import { CatalogLayout } from "@with-nx/simple-ui/organisms";
import { useCustomMobile } from "@with-nx/hooks-n-helpers";
import { HomeHero, HomeSearch } from "@with-nx/simple-ui/templates";
import heroImage from "../public/assets/home/bg_desktop.png";
import dynamic from "next/dynamic";
import React from "react";

const HomeCatalogTestimonials = dynamic<{
  mobile: boolean;
  tablet: boolean;
  hero: any;
}>(
  () =>
    import("@with-nx/simple-ui/templates").then(
      (c) => c.HomeCatalogTestimonials
    ),
  {
    ssr: false,
  }
);

const CMSContentContainer = dynamic<{
  mobile: boolean;
  tablet: boolean;
}>(() =>
  import("@with-nx/simple-ui/templates").then((c) => c.CMSContentContainer)
);

export const getServerSideProps = async () => {
  const description =
    "Elevate your production with Broadway Media, committed to providing affordable theatrical resources. Explore Scenic Projections, projectors for stage, wireless body mics, & more.";

  const hero = {
    image: "/assets/home/bg_tablet_dots.png",
    video: "/assets/home/video.mp4",
    links: [
      {
        label: "BodyMics",
        description: "Simple Wireless Audio Solutions",
        href: "/stage-gear/wirelesssystems",
        positions: {
          desktop: {
            x: "57",
            y: "49",
          },
          tablet: {
            x: "60",
            y: "49",
          },
          mobile: {
            x: "24",
            y: "50",
            reverse: true,
          },
        },
      },

      {
        label: "Stage Projectors",
        description: "Big, Bright & Shadow Free",
        href: "/stage-gear",
        positions: {
          desktop: {
            x: "65.5",
            y: "24",
          },
          tablet: {
            x: "65",
            y: "22",
          },
          mobile: {
            x: "55",
            y: "23",
            reverse: true,
          },
        },
      },

      {
        label: "Choreography Guides",
        description: "Learn the Moves from Broadway's Best",
        href: "/choreography-guides",
        positions: {
          desktop: {
            x: "42",
            y: "78",
          },
          tablet: {
            x: "40",
            y: "70",
          },
          mobile: {
            x: "12",
            y: "78",
          },
        },
      },

      {
        label: "Scenic Projections",
        description: "Script Accurate. Beautifully Animated.",
        href: "/scenic-projections",
        positions: {
          desktop: {
            x: "25",
            y: "32",
          },
          tablet: {
            x: "12",
            y: "28",
          },
          mobile: {
            x: "12",
            y: "31",
          },
        },
      },
    ],
    gradients: {
      top: "var(--background)",
      bottom: "#32317F",
    },
  };

  return {
    props: {
      description,
      hero,
    },
  };
};

export default function Page({ description, hero }) {
  const mobile = useCustomMobile();
  const tablet = useCustomMobile(1024) && !mobile;

  return (
    <>
      <CatalogLayout resolvedUrl="/" description={description}>
        <HomeSearch mobile={mobile} />
        <HomeHero
          mobile={mobile}
          tablet={tablet}
          hero={hero}
          image={heroImage}
        />

        <HomeCatalogTestimonials mobile={mobile} tablet={tablet} hero={hero} />
        <CMSContentContainer mobile={mobile} tablet={tablet} />
      </CatalogLayout>
    </>
  );
}
