/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuth } from "@with-nx/auth";
import { useEffect, useState } from "react";
import Toast from "react-hot-toast";

import useService, { invalidateCache, useURL } from "./useService";
export interface OrganizationAddress {
  id: number;
  line1?: string;
  line2?: string;
  postal_code?: string;
  location?: {
    state?: {
      code: string;
      name: string;
      countryCode: string;
    };
    country?: {
      code: string;
      name: string;
    };
    city?: {
      id: string;
      stateCode: string;
      code?: string;
      name: string;
    };
  };
}

export interface OrganizationRental {
  id: number;
  label: string;
  type: string;
}

export interface Organization {
  id: string | number;
  name: string;
  phone_number?: string;
  email?: string;
  organization_address?: OrganizationAddress;
  type?: string;
  available_rentals?: OrganizationRental[];
}

export interface AdminUser {
  id: number;
  user: {
    id: string | number;
    name: string;
    email: string;
  };
  role: string;
  permissions?: {
    [key: string]: boolean;
  };
}

export interface User {
  id: string | number;
  token: string;
  accesses?: string[];
}

export interface OrganizationContact {
  id: string | number;
  email?: string;
  phone?: string;
  name?: string;
  organization_id: string | number;
  role?: string;
}

export const ROLES_LABELS = {
  admin: "Admin",
  sub_admin: "Sub Admin",
};

export const useAdmins = (organizationId?: string) => {
  const [admins, _admins] = useState<AdminUser[]>([]);

  const [loading, _loading] = useState<boolean>(true);
  const [saving, _saving] = useState<boolean>(false);
  const [error, _error] = useState<boolean>(true);
  const [errorMessage, _errorMessage] = useState<string>("");

  const auth = useAuth();

  const makeRequestToBackstage = useService("accounts", {
    cache: 60_000,
  });

  useEffect(() => {
    if (auth.authState.status !== "authenticated") {
      return;
    }
  }, [auth.authState.status]);

  const fetchAdmins = async (organizationId?: string) => {
    try {
      _loading(true);
      // _error(false);
      // _errorMessage("");

      if (organizationId) {
        const response = await makeRequestToBackstage(
          "GET",
          `/api/v2/organizations/${organizationId}/members`,
          {},
          {
            Authorization: `Bearer ${auth.authState.session?.token}`,
          }
        );

        if (response["data"]) {
          _admins(response["data"]);
        }
      }
    } catch (error) {
      _error(true);
    } finally {
      _loading(false);
    }
  };

  const inviteAdmin = async (
    name: string,
    email: string,
    permissions: {
      [key: string]: boolean;
    }
  ) => {
    _saving(true);
    _error(false);
    _errorMessage("");

    try {
      const [firstName, ...names] = name.split(" ");
      const lastName = names.join(" ");

      const response = await makeRequestToBackstage(
        "POST",
        `/api/v2/organizations/${organizationId}/add_sub_admin`,
        {
          sub_admin: {
            email,
            first_name: firstName,
            last_name: lastName,
            permissions,
          },
        },
        {
          Authorization: `Bearer ${auth.authState.session?.token}`,
          "Content-Type": "application/vnd.api+json",
        }
      );

      if (response?.["errors"]?.[0]) {
        Toast.error(response?.["errors"]?.[0]);
      }

      Toast.success("Sub Admin invited successfully!");

      return response;
    } catch (error) {
      let message;

      if (error instanceof Error) message = error.message;
      else message = String(error);
      _error(true);
      _errorMessage(message);

      return {
        error: message,
      };
    } finally {
      _saving(false);
    }
  };

  const editAdminPermissions = async (
    id: string,
    userId: string,
    permissions: {
      [key: string]: boolean;
    }
  ) => {
    _saving(true);

    try {
      const response = await makeRequestToBackstage(
        "PUT",
        `/api/v2/organization_users/${id}/update_sub_admin`,
        {
          sub_admin: {
            user_id: userId,
            permissions,
          },
        },
        {
          Authorization: `Bearer ${auth.authState.session?.token}`,
          "Content-Type": "application/vnd.api+json",
        }
      );

      if (response?.["errors"]?.[0]) {
        Toast.error(response?.["errors"]?.[0]);
      }

      Toast.success("Sub Admin updated successfully!");

      return response;
    } catch (error) {
      let message;

      if (error instanceof Error) message = error.message;
      else message = String(error);
      _error(true);
      _errorMessage(message);

      return {
        error: message,
      };
    } finally {
      _saving(false);
    }
  };

  const revokeAccess = async (id: string | number) => {
    _saving(true);

    try {
      const response = await makeRequestToBackstage(
        "DELETE",
        `/api/v2/organization_users/${id}/remove_sub_admin`,
        {},
        {
          Authorization: `Bearer ${auth.authState.session?.token}`,
          "Content-Type": "application/vnd.api+json",
        }
      );

      if (response?.["errors"]?.[0]) {
        Toast.error(response?.["errors"]?.[0]);
      }

      Toast.success("Sub Admin removed successfully!");

      return response;
    } catch (error) {
      console.error("error", error);
    } finally {
      _saving(false);
    }

    return;
  };

  return {
    loading,
    saving,
    error,
    errorMessage,
    _errorMessage,

    admins,
    fetchAdmins,
    inviteAdmin,
    editAdminPermissions,
    revokeAccess,
  };
};

export const useUsers = (organizationId?: string) => {
  const [users, _users] = useState<User[]>([]);

  const [loading, _loading] = useState<boolean>(true);
  const [saving, _saving] = useState<boolean>(false);
  const [error, _error] = useState<boolean>(true);
  const [errorMessage, _errorMessage] = useState<string>("");

  const auth = useAuth();

  const makeRequestToBackstage = useService("accounts", {
    cache: 60_000,
  });

  const fetchUsers = async (organizationId?: string) => {
    try {
      _loading(true);
      _error(false);
      _errorMessage("");

      if (organizationId) {
        try {
          _loading(true);
          _error(false);

          if (organizationId) {
            const response = await makeRequestToBackstage(
              "GET",
              `/api/v2/organizations/${organizationId}/global_access_keys`,
              {},
              {
                Authorization: `Bearer ${auth.authState.session?.token}`,
              }
            );

            if (response["data"]) {
              _users(response["data"]);
            }
          }
        } catch (error) {
          _error(true);
          _errorMessage(error as string);
        } finally {
          _loading(false);
        }
      }
    } catch (error) {
      _error(true);
    } finally {
      _loading(false);
    }
  };

  const addUsers = async (emails: string[], rentals: number[]) => {
    _saving(true);

    try {
      _saving(true);

      const response = await makeRequestToBackstage(
        "POST",
        `/api/v2/organizations/${organizationId}/global_access_keys`,
        {
          emails,
          rental_ids: rentals,
        },
        {
          Authorization: `Bearer ${auth.authState.session?.token}`,
        }
      );

      if (response?.["errors"]?.[0]) {
        Toast.error(response?.["errors"]?.[0]);
      }

      Toast.success("Users invited successfully!");

      return response;
    } catch (error) {
      console.error("error", error);
    } finally {
      _saving(false);
    }

    return;
  };

  const revokeAccessUser = async (id: string | number) => {
    _saving(true);

    try {
      const response = await makeRequestToBackstage(
        "DELETE",
        `/api/v2/global_access_keys/${id}`,
        {},
        {
          Authorization: `Bearer ${auth.authState.session?.token}`,
          "Content-Type": "application/vnd.api+json",
        }
      );

      if (response?.["errors"]?.[0]) {
        Toast.error(response?.["errors"]?.[0]);
      }

      Toast.success("User removed successfully!");
      return response;
    } catch (error) {
      console.error("error", error);
    } finally {
      _saving(false);
    }

    return;
  };

  return {
    loading,
    saving,
    error,
    errorMessage,
    _errorMessage,

    users,
    fetchUsers,
    addUsers,
    revokeAccessUser,
  };
};

export const useContacts = (organizationId?: string) => {
  const [contacts, _contacts] = useState<OrganizationContact[]>([]);

  const [loading, _loading] = useState<boolean>(true);
  const [saving, _saving] = useState<boolean>(false);
  const [error, _error] = useState<boolean>(true);
  const [errorMessage, _errorMessage] = useState<string>("");

  const auth = useAuth();

  const makeRequestToBackstage = useService("accounts", {
    cache: 60_000,
  });

  useEffect(() => {
    if (auth.authState.status !== "authenticated") {
      return;
    }
  }, [auth.authState.status]);

  const fetchContacts = async (organizationId?: string) => {
    try {
      _loading(true);
      _error(false);
      _errorMessage("");

      if (organizationId) {
        try {
          _loading(true);
          _error(false);

          if (organizationId) {
            const response = await makeRequestToBackstage(
              "GET",
              `/api/v2/organizations/${organizationId}/contacts`,
              {},
              {
                Authorization: `Bearer ${auth.authState.session?.token}`,
              }
            );

            if (response["data"]) {
              _contacts(response["data"]);
            }
          }
        } catch (error) {
          _error(true);
          _errorMessage(error as string);
        } finally {
          _loading(false);
        }
      }
    } catch (error) {
      _error(true);
    } finally {
      _loading(false);
    }
  };

  const createContact = async (
    contact: Partial<OrganizationContact> & {
      first_name: string;
      last_name: string;
    }
  ) => {
    _saving(true);

    try {
      _saving(true);

      const response = await makeRequestToBackstage(
        "POST",
        `/api/v2/organizations/${organizationId}/contacts`,
        {
          contact,
        },
        {
          Authorization: `Bearer ${auth.authState.session?.token}`,
        }
      );

      if (response?.["errors"]?.[0]) {
        Toast.error(response?.["errors"]?.[0]);
      }

      Toast.success("Contact created successfully!");

      return response;
    } catch (error) {
      console.error("error", error);
    } finally {
      _saving(false);
    }

    return;
  };

  const deleteContact = async (id: string | number) => {
    _saving(true);

    try {
      const response = await makeRequestToBackstage(
        "DELETE",
        `/api/v2/contacts/${id}`,
        {},
        {
          Authorization: `Bearer ${auth.authState.session?.token}`,
          "Content-Type": "application/vnd.api+json",
        }
      );

      if (response?.["errors"]?.[0]) {
        Toast.error(response?.["errors"]?.[0]);
      }

      Toast.success("Contact removed successfully!");
      return response;
    } catch (error) {
      console.error("error", error);
    } finally {
      _saving(false);
    }

    return;
  };

  return {
    loading,
    saving,
    error,
    errorMessage,
    _errorMessage,

    contacts,
    fetchContacts,
    createContact,
    deleteContact,
  };
};

export const useOrganizations = () => {
  const [organizations, _organizations] = useState<Organization[]>([]);

  const [loading, _loading] = useState<boolean>(true);
  const [saving, _saving] = useState<boolean>(false);
  const [error, _error] = useState<boolean>(true);
  const [errorMessage, _errorMessage] = useState<string>("");

  const auth = useAuth();
  const url = useURL("accounts");

  const makeRequestToBackstage = useService("accounts", {
    cache: 60_000,
  });

  useEffect(() => {
    if (auth.authState.status !== "authenticated") {
      return;
    }

    fetchOrganizations();
  }, [auth.authState.status]);

  const fetchOrganizations = async () => {
    try {
      _loading(true);
      _error(false);

      const response = await makeRequestToBackstage(
        "GET",
        `/api/v2/organizations`,
        {},
        {
          Authorization: `Bearer ${auth.authState.session?.token}`,
        }
      );

      if (response["data"]) {
        _organizations(response["data"]);
      }
    } catch (error) {
      _error(true);
    } finally {
      _loading(false);
    }
  };

  const createOrganization = async (
    name: string,
    phone_number: string,
    type: string
  ) => {
    try {
      _saving(true);
      _error(false);
      _errorMessage("");

      const path = "/api/v2/organizations";

      const response = await makeRequestToBackstage(
        "POST",
        path,
        {
          organization: {
            name,
            phone_number,
            type,
          },
        },
        {
          Authorization: `Bearer ${auth.authState.session?.token}`,
        }
      );

      if (response?.["errors"]?.[0]) {
        Toast.error(response?.["errors"]?.[0]);
      }

      if (url) {
        invalidateCache(url, path);
      }

      Toast.success("Organization created successfully!");

      _saving(false);
      return response;
    } catch (error) {
      let message;

      if (error instanceof Error) message = error.message;
      else message = String(error);

      Toast.error(message);
    } finally {
      _saving(false);
      return;
    }
  };

  const editOrganization = async (
    id: string,
    name: string,
    phone_number: string,
    type: string
  ) => {
    try {
      _saving(true);
      _error(false);
      _errorMessage("");

      const response = await makeRequestToBackstage(
        "PUT",
        `/api/v2/organizations/${id}`,
        {
          organization: {
            name,
            phone_number,
            type,
          },
        },
        {
          Authorization: `Bearer ${auth.authState.session?.token}`,
        }
      );

      if (response?.["errors"]?.[0]) {
        _saving(false);
        Toast.error(response?.["errors"]?.[0]);
      }

      Toast.success("Organization updated successfully!");

      _saving(false);
      return response;
    } catch (error) {
      let message;

      if (error instanceof Error) message = error.message;
      else message = String(error);

      Toast.error(message);
    } finally {
      _saving(false);
      return;
    }
  };

  const editOrganizationAddress = async (id: string | number, address: any) => {
    _saving(true);
    const response = await makeRequestToBackstage(
      "PUT",
      `/api/v2/organizations/${id}`,
      {
        organization: {
          organization_address_attributes: {
            ...address,
          },
        },
      },

      {
        Authorization: `Bearer ${auth.authState.session?.token}`,
      }
    );

    if (response?.["errors"]?.[0]) {
      Toast.error(response?.["errors"]?.[0]);
    }

    Toast.success("Organization updated successfully!");

    _saving(false);
    return response;
  };

  return {
    organizations,
    createOrganization,
    editOrganization,
    loading,
    saving,
    error,
    errorMessage,
    _errorMessage,
    editOrganizationAddress,
  };
};
