import * as Helpscout from "helpscout-beacon-react";
import { NextRouter, useRouter } from "next/router";
import { useEffect } from "react";

import Database from "./Database";
import { useNoPrint } from "./usePrint";
import { CampaignHelper } from "@with-nx/auth";

const isDocument = () => {
  if (typeof window !== "undefined") {
    return true;
  }
  return false;
};

function isProduction() {
  return process.env.NODE_ENV === "production";
}

const bootstrapCaching = () => {
  if (isDocument()) {
    Database.bootstrap();
  }
};

const bootstrapFacebook = (router: NextRouter) => {
  if (isDocument() && isProduction()) {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((Pixel) => {
        try {
          Pixel.init(process.env["NEXT_PUBLIC_FB_PIXEL_ID"] || "");
          Pixel.pageView();

          if (router) {
            router.events.on("routeChangeComplete", () => {
              Pixel.pageView();
            });
          }
        } catch (error) {
          console.error(error);
        }
      });
  }
};

const bootstrapHotjar = () => {
  if (isDocument()) {
    import("react-hotjar")
      .then((x) => x.default)
      .then((Hotjar) => {
        try {
          Hotjar.hotjar.initialize(3506718, 6);
        } catch (error) {
          console.error(error);
        }
      });
  }
};

const bootstrapMetricool = () => {
  if (isDocument()) {
    try {
      Injecter.injectBodyScript({
        body: `function loadScript(a){var b=document.getElementsByTagName("head")[0],c=document.createElement("script");c.type="text/javascript",c.async=true,c.defer=true,c.src="https://tracker.metricool.com/resources/be.js",c.onreadystatechange=a,c.onload=a,b.appendChild(c)}loadScript(function(){beTracker.t({hash:"df4ca4d68686a0eecbedb2b8e21183aa"})});`,
        tag: "metricool-script",
        defer: true,
      });
    } catch (error) {
      console.error(error);
    }
  }
};

const bootstrapHelpscout = () => {
  if (isDocument()) {
    try {
      Helpscout.Helpscout.inject(
        process.env["NEXT_PUBLIC_HELPSCOUT_BEACON_ID"]
      );
    } catch (error) {
      console.error(error);
    }
  }
};

const bootstrapController = (router: NextRouter) => {
  if (isDocument()) {
    if (router) {
      router.events.on("routeChangeComplete", () => {
        window.document.body.scrollTo(0, 0);
      });
    }
  }
};

const bootstrapServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register("/sw.js").then(
      () => {},
      (error) => {
        console.error(error);
      }
    );
  } else {
    console.error("🤖", "Not Supported");
  }
};

const checkCampaign = (router: NextRouter) => {
  const code = router.query?.["campaign"];

  if (code) {
    CampaignHelper.store(code as string);
  }
};

export const useApplication = () => {
  const development = process.env.NODE_ENV !== "production";
  const router = useRouter();

  useEffect(() => {
    const maintenance = process.env["NEXT_PUBLIC_MAINTENANCE"] === "true";
    if (maintenance) {
      router.push("/maintenance");
      return;
    }

    bootstrapCaching();
    bootstrapController(router);
    bootstrapServiceWorker();

    const https = window.location.protocol === "https:";
    const staging =
      window.location.hostname !== "broadwaymedia.com" &&
      window.location.hostname !== "www.broadwaymedia.com";

    if (!development && !staging) {
      bootstrapFacebook(router);
      if (https) {
        bootstrapHotjar();
        bootstrapHelpscout();
        bootstrapMetricool();
      }
    }
  }, []);

  useEffect(() => {
    checkCampaign(router);
  }, [router]);

  const print = useNoPrint();

  return {
    print,
  };
};

export default useApplication;

class Injecter {
  static injectBodyScript({
    url,
    body,
    async,
    defer,
    type = "text/javascript",
    tag,
  }: {
    url?: string;
    body?: string;
    async?: boolean;
    type?: string;
    tag?: string;
    defer?: boolean;
  }) {
    if (!window || !window.document) {
      throw new Error(
        "Injecter.injectBodyScript: window or window.document is not defined"
      );
    }

    if (tag && document.querySelector(`script[data-tag="${tag}"]`)) {
      return;
    }

    const script = document.createElement("script");

    if (url) {
      script.src = url;
    }
    if (async) {
      script.async = true;
    }
    if (body) {
      script.innerHTML = body;
    }
    if (type) {
      script.type = "text/javascript";
    }
    if (tag) {
      script.setAttribute("data-tag", tag);
    }
    if (defer) {
      script.defer = true;
    }

    document.body.appendChild(script);
  }
}
